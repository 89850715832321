// Fonts
@font-face {
    font-family: "ClashGrotesk";
    src: url("./assets/Fonts/ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "ClashGrotesk";
    src: url("./assets/Fonts/ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Semibold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "ClashGrotesk";
    src: url("./assets/Fonts/ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "ClashGrotesk";
    src: url("./assets/Fonts/ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Light.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
}



@font-face {
    font-family: "ClashGrotesk";
    src: url("./assets/Fonts/ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "OakSans";
    src: url("./assets/Fonts/oak-sans-cufonfonts/OakSans-Bold-BF648bbebd3aa23.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "OakSans";
    src: url("./assets/Fonts/oak-sans-cufonfonts/OakSans-Light-BF648bbebce0c8a.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: "OakSans";
    src: url("./assets/Fonts/oak-sans-cufonfonts/OakSans-Medium-BF648bbebd57de3.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "OakSans";
    src: url("./assets/Fonts/oak-sans-cufonfonts/OakSans-Regular-BF648bbebd37e68.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "OakSans";
    src: url("./assets/Fonts/oak-sans-cufonfonts/OakSans-SemiBold-BF648bbebd4a4eb.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Tropiline";
    src: url("./assets/Fonts/Freepreset.net-Tropiline/Tropiline/Tropiline/Tropiline-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Tropiline";
    src: url("./assets/Fonts/Freepreset.net-Tropiline/Tropiline/Tropiline/Tropiline-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: "Tropiline";
    src: url("./assets/Fonts/Freepreset.net-Tropiline/Tropiline/Tropiline/Tropiline-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Tropiline";
    src: url("./assets/Fonts/Freepreset.net-Tropiline/Tropiline/Tropiline/Tropiline-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

body {
    font-family: "ClashGrotesk", "OakSans", "Tropiline", sans-serif;
}

// Footer
@import url("./components/organisms/Footer/FooterStyle.scss");

// Components
@import url("./components/atoms/Buttons/ButtonStyle/ButtonStyle.scss");

// Sliders
// @import url("./components/organisms/Sections/StudyDestinations/StudyDestinationStyle.scss");

// Pages
@import url("./modules/Home/HomePageStyle.scss");
@import url("./modules/PageNotFound/PageNotFound.scss");

@mixin scrollbar() {
    &::-webkit-scrollbar {
        width: 12px;
        height: 20px;
        transition:
            width 0.5s ease,
            height 0.5s ease;
    }
    &::-webkit-scrollbar:hover {
        width: 15px;
        height: 20px;
    }
    &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 7px;
        background-color: #fd5b2c80;
        box-shadow:
            inset -1px -1px 0px rgba(0, 0, 0, 0.05),
            inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-thumb:hover {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 7px;
        background-color: #fd5b2c;
        box-shadow:
            inset -1px -1px 0px rgba(0, 0, 0, 0.05),
            inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

// .subheading-blue {
//     font-family: ClashGrotesk;
//     font-style: normal;
//     color: #052343;
//     font-weight: 700;
// }
// .subheading-red {
//     font-family: Tropiline;
//     font-style: normal;
//     color: #fd5b2c;
//     font-weight: 700;
// }

.custom-swal-font{
    font-family: OakSans, sans-serif;
}
.custom-btn{
    background-color: #052343;
    width: 100px;
}
.error-custom-btn{
    background-color: #fd5b2c;
    width: 100px;
}
body {
    scroll-behavior: smooth;
    @include scrollbar();
}
