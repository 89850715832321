.swiper-pagination {
  position: relative;
  margin-top: 25px;
  .swiper-pagination-bullet {
    background-color: #024378 !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &.swiper-pagination-bullet-active {
      
      border-color: #fff;
      border-style: solid;
      outline: #024378;
      outline-style: solid;
      outline-width: 1px;
    }
  }
}
