/* body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: var(--purple);
    --accent: var(--white);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
    padding-bottom: 100px;
    overflow: hidden;
  } */
  
  /* #root {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
/*   
  * {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
  }
   */
  /* h1,
  h2,
  h3 {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  h1 {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0 40px;
  }
  
  h2 {
    font-weight: 400;
    margin: 50px 0 10px;
  }
  
  p {
    margin: 0 0 30px 0;
    font-size: 18px;
  }
  
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-top: 1px dotted var(--accent);
    background-color: transparent;
    background-image: radial-gradient(
      circle at 50% 50%,
      var(--background) 20%,
      transparent 20.5%,
      transparent 49.5%,
      var(--background) 50%
    );
  
    background-size: 15px 15px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    -moz-backdrop-filter: blur(20px);
    font-size: 14px;
    line-height: 14px;
  }
  
  footer svg {
    margin-right: 20px;
  }
  
  footer a {
    text-decoration: none;
    color: var(--accent);
  }
  
  code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }
  
  .refresh {
    padding: 5px;
    position: absolute;
    border: 1px dotted var(--accent);
    border-radius: 5px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .refresh path {
    fill: var(--accent);
  } */
  
  .box {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: var(--accent);
    margin: 0;
  }
  
  .menu {
    filter: drop-shadow(1px 1px 1px #5116a9);
    width: 300px;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--accent);
  }
  
  li {
    color: var(--background);
    display: block;
    transform-origin: -20px 50%;
  }
  
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 10px;
  }
  
  /* button {
    -webkit-appearance: button;
    background: var(--accent);
    color: var(--background);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  
  /* button path {
    fill: var(--background);
  } */
  
  /* input {
    accent-color: var(--accent);
  }
   */
  /* .controls {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 50px;
    align-items: center;
  }
   */
  label {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  /* input[type="number"] {
    border: 0;
    border-bottom: 1px dotted var(--accent);
    color: var(--accent);
    margin-left: 10px;
    background: var(--background);
    width: 50px;
  }
  
  input[type="number"]:focus {
    outline: none;
    border-bottom: 2px solid var(--accent);
  }
  
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
   */
  .example {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .item {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: var(--accent);
    color: var(--background);
    font-size: 240px;
    line-height: 260px;
    font-weight: 700;
    border-radius: 30px;
  }
  