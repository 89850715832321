.send-email-container {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    padding-inline-end: 10px;
    input {
        width: 100%;
        border: none;
        padding: 8px;
    }
    input:focus-visible{
        outline: none;
    }
    img {
        object-fit: contain;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
}

.footer-list{
    list-style-type: none;
    padding-inline-start: 6px;
    text-align: left;
    margin-top: 0;
    line-height: 1;
}

// style={{
//     listStyleType: "none",
//     paddingInlineStart: 5,
//     textAlign: "left",
//     marginTop: 0,
//     lineHeight: 1.8,
//   }}
