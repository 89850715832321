// .international-offices-container {
//     background-color:#e8ecf0;
// }

.international-offices-container {
    background-image: url("../../assets/Images/Pictures/bg_pattern.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #e8ecf0;
    background-origin: 0;
    padding-top: 35px;
    padding-bottom: 35px;
}


// Global standards
.global-standards-container {
    background-image: url("../../assets/Images/Pictures/background_dark.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 35px;
    padding-bottom: 35px;
}

.professional-dev-email-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-color: #e8ecf0;
    border-top: #ffffff00;
    border-left: #ffffff00;
    border-right: #ffffff00;
    border-width: 1px;
    border-style: solid;
    padding-inline: 10px;
    align-items: center;
    input {
        width: 100%;
        background-color: #01203D;
        border: none;
        padding: 8px;
        color: #ffffff;
    }
    input::placeholder{
       color: #ffffff85;
    }
    input:focus-visible{
        outline: none;
    }
    
    
}
