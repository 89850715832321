.middle {
    display: flex;
    justify-content: center;
}

.page-not-found-img {
    width: 50%;
    height: auto;
    object-fit: cover;
}

.page-not-found-text {
    color: #fd5b2c;
    font-family: Clash Grotesk;
    font-weight: bold;
    margin: 0;
    font-size: 3rem;
    text-align: center;
}

.page-not-found-text::after {
    content: "Page not found.";
    font-family:
        Oak Sans,
        sans-serif;
    color: #01203d;
    font-weight: 600;
    font-size: 3rem;
    line-height: 0;
}

.page-not-found-description {
    color: #01203D;
    font-family:
        Oak Sans,
        sans-serif;
    font-weight: 400;
    margin: 0;
    font-size:1rem;
    text-align: center;
    line-height: 32px;
}
