// swiper-container{
//     width:100%;
//     height:100%
// }

.slider-main-container{
    position: relative;

}
.nav-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    border-color: #D8E6EF;
   padding: 8px 20px 8px 20px;
  
}

.nav-btn:hover{
    background-color: #1A2937;
}
// swiper-slide{
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
// }
// swiper-slide img{
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
// }